import React, { useState } from "react";
import "./CSS/CourseOfferCard.css";

import { Link, useNavigate } from "react-router-dom";

import { MENTORSHIP_COURSES } from "../../service/CoursesApi";

const MentorshipCourses = () => {
  const showCourseDetails = () => {
    console.log("course");
  };
  const [courseIndex, setIndex] = useState(0);

  return (
    <div
      className="courses-list"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        wordBreak: "break-word",
        marginTop: "3rem",
      }}
    >
      <h2
        className="expert-heading"
        style={{
          width: "80vw",
          textAlign: "center",
          fontWeight: "700",
          fontSize: "1.9rem",
        }}
      >
        Let Our Experts Take Your Career to the Higher Grounds
      </h2>
      <p
        className="expert-para"
        style={{ width: "80vw", textAlign: "center", fontWeight: "300" }}
      >
        The goal is to narrow the gap between industry needs and education by
        empowering individuals <br /> with the necessary skills to succeed in
        today's fast-paced and competitive job market.
      </p>
      <div style={{display:'flex'}}>
      <div className="mentors-image-card">
          <div className="mentor-img-and-name">
            <img src="/images/mentor.svg" alt="" />
            <p>Karan</p>
          </div>
          <div className="mentor-img-and-name">
            <img src="/images/mentor.svg" alt="" />
            <p>Karan</p>
          </div>
          <div className="mentor-img-and-name">
            <img src="/images/mentor.svg" alt="" />
            <p>Karan</p>
          </div>
        </div>

        <div className="mentors-details-card">
          <div className="name-and-profile">
            <div className="data-board">
              <img src="/images/data-board.svg" alt="" />
            </div>
            <div className="name-education">
              <h2>Karan</h2>
              <p>IIT-JEE Expert</p>
            </div>
            <div className="view-Profile-btn">
              <p>view profile</p>
            </div>
          </div>

          <div className="education-and-interest">
            <div className="chalkboard">
              <img src="/images/chalkboard.svg" alt="" />
            </div>
            <div className="mentor-edu">
              <h4>Education</h4>
              <p>IIT-Kanpur, India</p>
            </div>
            <div className="area-of-interest">
              <h4>Area of Interest</h4>
              <p>Physics, Chemistry, Mathematics</p>
            </div>
          </div>

          <div className="mentor-experience">
            <img src="/images/success.svg" alt="" />
            <p>20+ years of Experience</p>
          </div>

          <div className="telescope">
            <img src="/images/telescope.svg" alt="" />
          </div>
        </div>
      </div>

      {/* course - offers */}
      <div className="mentorship-course-container">

        <div className="course-card-conatiner">
              {MENTORSHIP_COURSES.map((course, index)=> 
                <div className={"course-card card" + index} onClick={()=>setIndex(--index)}>
                <p>{course.title}</p>
                <img src={course.imageUrl} alt="" />
                </div>
            
              )}
          </div>

       <div className="course-offer">
          <div className="course-offer-desc">
            <h5 style={{ margin: 0, fontSize: "1.2rem" }}>{MENTORSHIP_COURSES[courseIndex].title}</h5>
            <p style={{ fontSize: ".8rem" }}>
              {MENTORSHIP_COURSES[courseIndex].description}
            </p>
            <div className="all-btn">
              <Link style={{ color: "white" }} to="/programs" >
                  View More
              </Link>
            </div>
          </div>
        </div> 
      </div>
    </div>
  );
};

export default MentorshipCourses;
