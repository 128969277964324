import * as Congs from './Configurations'

export function getQuickInterests() {
  return ["DSA", "System", "Interview", "React", "Regenerate"];
}

export function getWebsiteBlogs(search, interest){
  console.log("fetching blogs")
  return  fetch(Congs.GET_ALL_BLOGS + "?search="+search+"&interest="+interest, Headers={"Content-Type": "text/markdown"})
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
}


export function getPopulerShortcuts()  {
  console.log("fetching shortcuts")
  return  fetch( Congs.GET_ALL_POPULER_BlOGS_SHORTCUTS)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};

