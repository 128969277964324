import React, { useState } from "react";
import "./ServiceCardDetails.css";
import {TypeWriterComponent} from '../Atoms/TypeWriter'

export default function ServiceCardDetails(props) {
  // handle responsive
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  // on mobile view
  const handleCardClick = (idx) => {
    if (window.innerWidth <= 850) {
      console.log(idx, activeIndex);
      if (activeIndex === idx) {
        // Clicking on the same card again should toggle its state
        setIsActive(!isActive);
      } else {
        // Clicking on a new card should reset the previous card
        setIsActive(true);
        setActiveIndex(idx);
      }
    }
  };

  // on Desktop view
  const handleMouseEnter = () => {
    if (window.innerWidth > 850) {
      setIsActive(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 850) {
      setIsActive(false);
    }
  };

  const [hovered, setHovered] = useState(false);
  const serviceDetail = props.serviceDetail;
  return (
    <div
      className={`single-card ${isActive ? "active" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleCardClick(serviceDetail.id)}
    >

      {!isActive ? (
        <div className="head-text">
          <img src={serviceDetail.imageUrl} alt={serviceDetail.title} />
            <div class="text-on-image">
              <h1>{serviceDetail.number}</h1>
              <h3>{serviceDetail.title}</h3>
            </div>
        </div>
      ) : (
      <div className="head-paragraph">
          <p className="typewriter-text">        <TypeWriterComponent content={serviceDetail.description}/></p>
      </div>
      )}
    </div>
  );
}
