const baseUrl = "/data/Jobs.json";


const headers = {
    'Accept':'application/json',
}

export function getAllJobOpennings()  {
  console.log("fetching getAllJobOpennings")
  return  fetch(baseUrl)
    .then((response) => response.json())
    .then((json) => json)
    .catch((e) => console.log("Error " + e));
};

