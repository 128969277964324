import PersonIcon from "@mui/icons-material/Person";
import SubjectIcon from "@mui/icons-material/Subject";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WorkIcon from "@mui/icons-material/Work";
import { Dashboard } from "@mui/icons-material";

export const COLOR_CODES = { primary: "#0F547E", secondary: "#28B2B6" };

export const Heading_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export const TEXT_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export function getButton_css(tab, selectedIcon) {
  return {
    color: tab.tag === selectedIcon ? "white" : "black",
    backgroundColor: tab.tag === selectedIcon ? "green" : "white",
  };
}

export const HEADERS = [
  { tag: "Home", path: "/home" },
  { tag: "Services", path: "/services" },
  { tag: "Programs", path: "/programs" },
  { tag: "Blog", path: "/blogs" },
  { tag: "Careers", path: "/careers" },
  { tag: "About Us", path: "/about-us" },
  { tag: "login", path: "/login" },
  // {tag:"chat", path:"/chat"},
];

export const FOOTERS = [
  { tag: "Home", path: "/home" },
  { tag: "Services", path: "/services" },
  { tag: "Programs", path: "/programs" },
  { tag: "About Us", path: "/about-us" },
  { tag: "Blog", path: "/blogs" },
  { tag: "Careers", path: "/careers" },
];

export const DASHBOARD_TABS = [
  {tag: "Dashboard", path:"/dashboard", icon:<Dashboard />},
  { tag: "Subjects", path: "/dashboard/subjects", icon: <SubjectIcon /> },
  // { tag: "Other", path: "/dashboard/others", icon: <AcUnitIcon /> },
  { tag: "Blogs", path: "/dashboard/blogs", icon: <LibraryBooksIcon /> },
  // {tag:"Calender", path: "/dashboard/calender",icon: <CalendarMonthIcon/>},
  {tag:"Jobs", path: "/dashboard/jobs",icon: <WorkIcon/>},
  { tag: "profile", path: "/dashboard/profile", icon: <PersonIcon /> },
];

export const SUBJECT_SIDE_NAV = [
  { tag: "Sessions" },
  { tag: "Quick-notes" },
  { tag: "Practice" },
  { tag: "Assignment" },
  {tag:"Progress"},
  // {tag:"Books"},
  // {tag:"Exam"},
];


export const QUICK_LINKS = ["Regenrate", "Examples", "Videos"];

export const Home_DIV3_SERVICE = [
  {
    id: 1,
    title: "Personalized Learning Using AI",
    number: "01",
    imageUrl: "/images/temp/personalised.jpg",
    description:
      "Our advanced AI model, ByteGpt, assesses each candidate's performance and learning curve. It generates customised learning paths, enabling candidates to learn at their own pace. We provide subject-specific materials ranging from basic to advanced levels, based on each candidate's proficiency.ByteGpt continuously monitors performance and provides valuable feedback to aid improvement.",
  },
  {
    id: 2,
    title: "Comprehensive Mentorship",
    number: "02",
    imageUrl: "/images/temp/mentorship.jpeg",

    description:
      "Our mentorship programs focus on improving skills and knowledge for technical job interviews in various technology-related roles.These programs offer structured guidance, practice, and support to enhance a candidate's chances of success during the interview process.",
  },

  {
    id: 3,
    title: "Ai-Driven Candidates Portal",
    number: "03",
    imageUrl: "/images/temp/portal.jpeg",

    description:
      "ByteGpt empowers students to learn at their own pace.It creates adaptive learning paths based on individual progress and practice.ByteGpt tailors learning content based on the candidate's level of understanding and learning journey.ByteGpt continuously tracks candidate progress and provides precise feedback.We assist candidates in improving and achieving their targeted goals at their own pace.",
  },

  {
    id: 4,
    title: "Interview preparetion and placements",
    number: "04",
    imageUrl: "/images/temp/interview.jpeg",

    description:
      "Our mentorship program offers a comprehensive understanding of industry-required skills.Experienced professionals guide candidates in their preparation, conduct mock interviews, assist with projects, and lead sessions on specific topics.We provide tailored support for interview preparation, enhancing candidates' confidence and readiness.",
  },
  {
    id: 5,
    title: "Best Fit Job for Candidates",
    number: "05",
    imageUrl: "/images/temp/job.jpeg",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
];



export const SERVICE_CARD_DETAILS = [
  {
    id: 1,
    title: "Personalized Learning Using AI",
    number: "01",
    imageUrl: "/images/temp/personalised.jpg",
    description:
      "Our advanced AI model, ByteGpt, assesses each candidate's performance and learning curve. It generates customised learning paths, enabling candidates to learn at their own pace. We provide subject-specific materials ranging from basic to advanced levels, based on each candidate's proficiency.ByteGpt continuously monitors performance and provides valuable feedback to aid improvement.",
  },
  {
    id: 2,
    title: "Comprehensive Mentorship",
    number: "02",
    imageUrl: "/images/temp/mentorship.jpeg",

    description:
      "Our mentorship programs focus on improving skills and knowledge for technical job interviews in various technology-related roles.These programs offer structured guidance, practice, and support to enhance a candidate's chances of success during the interview process.",
  },

  {
    id: 3,
    title: "Ai-Driven Candidates Portal",
    number: "03",
    imageUrl: "/images/temp/portal.jpeg",

    description:
      "ByteGpt empowers students to learn at their own pace.It creates adaptive learning paths based on individual progress and practice.ByteGpt tailors learning content based on the candidate's level of understanding and learning journey.ByteGpt continuously tracks candidate progress and provides precise feedback.We assist candidates in improving and achieving their targeted goals at their own pace.",
  },
  {
    id: 4,
    title: "Soft Skills, Communication, and Personality Development",
    number: "04",
    imageUrl: "/images/temp/softskills.jpeg",

    description:
      "In addition to technical training, we provide essential soft skills, communication skills, and critical thinking skills.We offer mock interviews, live industry projects, and other practical experiences to prepare students for the job market.",
  },
  {
    id: 5,
    title: "Interview preparetion and placements",
    number: "05",
    imageUrl: "/images/temp/interview.jpeg",

    description:
      "Our mentorship program offers a comprehensive understanding of industry-required skills.Experienced professionals guide candidates in their preparation, conduct mock interviews, assist with projects, and lead sessions on specific topics.We provide tailored support for interview preparation, enhancing candidates' confidence and readiness.",
  },
  {
    id: 6,
    title: "Best Fit Job for Candidates",
    number: "06",
    imageUrl: "/images/temp/job.jpeg",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
];
