import React, { useEffect, useState } from "react";
import ServiceCard from "../../Molecule/HomePageServiceCard";
import { SearchButton } from "../../Atoms/Buttons";
import "./CSS/HomePageFirstDiv.css";
import { TypeWriterComponent } from "../../Atoms/TypeWriter";

export default function HomePageFirstDiv({ props }) {
  const [searchText, setSearchText] = useState("");
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [typewriter, setTypeWriteContent] = useState(`Start Your Tech Journey, Where
  Human Talent Meets Generative AI. Our platform analyses resumes,
  organises skill matches, and moulds careers. Enter a World of
  Code-Driven Opportunities to Witness Your Future Take Shape.`)

  // console.log("mail",searchText)

  // props.getRegisterMail(searchText);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      // console.log(scrollY);
      if (scrollY >= 1000) {
        setShouldAnimate(true);
      } else {
        // setShouldAnimate(false)
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="about-us">
      <div className="title1">
        <img className="poster-img" src="/images/home-frame.jpg" alt="" />
        <p className="Heading"> Educate & Employ With AI</p>
      </div>
      {/* <div className="title2">
        <p className="title-para-data">
          {" "}
          <span className="end-img">
            <p className="circular-end">&</p>
            <img className="plant-img" src="/images/plant-img.svg" alt="" />
          </span>
          Your Growth Expedition Starts Here
        </p>
      </div> */}
      <div className="homepage-paragraph">
        <p className="paragraph">
          The secret to our success is a fast-paced learning environment, an
          extreme ownership spirit, and a fun culture.{" "}
          {/* <span className="learn-more">Learn more...</span> */}
        </p>
        <SearchButton setSearchText={setSearchText} searchText={searchText} />
      </div>

      <div className="company-details">
        <div className="company-description">
          <p>
          <TypeWriterComponent content= {typewriter}/>
          </p>
        </div>
        <div className="company-video">
          <video src="./videos/ai.mp4" type="video/mp4" controls></video>
        </div>
        {/* {isDecode && } */}
        <div className={`de-code ${shouldAnimate ? "animate" : ""}`}>
          <h1>De-Code</h1>
        </div>
        <ServiceCard />
      </div>
    </div>
  );
}
