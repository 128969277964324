import React, { useEffect, useState } from "react";
import MentorshipCourses from "./MentorshipCourses";
import "./CSS/Home.css";
import HomePageFirstDiv from "./HomePageFirstDiv";
import CoursePrograms from "../../Organism/Programs";
import {
  HOME_PLACEMENT_COMPANY_LOGOS,
  HOME_STATS,
} from "../../service/OurMembersAndTestimonialsApi";
import CirculerDiv from "../../Atoms/CirculerDiv";
import { ViewAllBlogs, ViewAllCourses } from "../../Atoms/Buttons";
import Sinewave from "../../Atoms/Sinewave";
import BlogsCardDetails from "../BlogsCardPage/BlogsCardDetails";
import * as CoursesApi from "../../service/CoursesApi";
import TestimonialCard from "../../Molecule/TestimonialCard";
import * as BlogsService from "../../service/BlogsApi";
import * as OurMembersAndTestimonialsApi from "../../service/OurMembersAndTestimonialsApi";
import { BlogCard } from "../../Molecule/BlogsCard";

export default function Home({props}) {
  const [courses, setCourses] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const [placementCompanies, setPlacementCompanies] = useState([]);
  const [stats, setStats] = useState({});

  useEffect(() => {
    CoursesApi.getAllCourses("","").then((cs) => setCourses(cs));
    BlogsService.getWebsiteBlogs("","").then((response) =>setBlogs(response.response));
    OurMembersAndTestimonialsApi.getAllStats().then(st=> setStats(st))
    OurMembersAndTestimonialsApi.getAllPlacementCompanies().then(companies=> setPlacementCompanies(companies) )
  }, []);

    
  return (
    <div>
      <HomePageFirstDiv  />

      <div className="testi">
        <a href="#testimonial">testimonials</a>
        <div className="arrow"></div>
        <div className="arrow1"></div>
        <div className="arrow2"></div>
      </div>

      <MentorshipCourses />
      <div className="our-vision">
        <div className="vision-data">
          <h2>Our Vision</h2>
          <p>
            We provide comprehensive and in-depth training on all subjects,
            ensuring our students gain practical knowledge that prepares them
            for their dream company. Our primary objective is to equip our
            students with industry-relevant skills and knowledge, making them
            job-ready upon completion of our program.
          </p>
          <b></b>
          <p>
            To gauge their progress and understanding, we conduct mock
            interviews and tests, allowing them to assess their performance and
            identify areas for improvement. Furthermore, we believe in hands-on
            learning, which is why we assign industry-based projects and
            assignments to enhance their practical skills. 
          </p>
        </div>
      </div>
      <div className="statistics">
        <h2>We Take Pride in Our Numbers</h2>
        <p style={{ width: "90vw", fontSize: "1rem" }}>
          Leveraging the Transformative Potential of Statistics and Data for
          Unmatched Success and Growth
        </p>

        <div className="placement-stats">
            <img src='/images/temp/placementStats.svg'  />
        </div>
      
      </div>

      <div className="alumni-works-at">
        <div className="alum-container">
          <h2>Our Alumni Works At</h2>
          <p style={{ textAlign: "center" }}>
            Our Alumni Work at Leading Global Companies: A Testimony to the
            Unwavering Dedication to Excellence and Unparalleled Career
            Advancement Opportunities Offered Here
          </p>
        </div>
        <div className="company-logos">
          {placementCompanies.map((placementCompany, index) => (
            <img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} />
          ))}
        </div>
        <div className="company-logos-two">
          {placementCompanies.map((placementCompany, index) => (
            <img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} />
          ))}
        </div>
      </div>
      <div
        className="courses-list"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "6rem",
        }}
      >
        <h2 className="courses-heading" style={{}}>
          Are You Ready <br /> to Accelerate Your Learning?
        </h2>
        <p className="courses-para">
          Diverse Courses for Every Aspiring Learner: Unlocking Knowledge and
          Skills Across Varied Disciplines
        </p>
        <CoursePrograms courses={courses} />
        {/* <button> view more </button> */}
        <ViewAllCourses />

        {/* Testimonials */}

        <TestimonialCard />
        {/* Blog Area */}

        <div className="blog-area">
          <div>
            <h1>Our Blogs</h1>
          </div>

          <div className="blog-desc">
            <p style={{}}>
              Elevating Your Journey from Education to Employment
            </p>
          </div>

          <div className="blog-cards-container">
            {blogs.map((blog) => (
              <BlogCard blog={blog} />
            ))}
          </div>
        </div>

        <ViewAllBlogs />
      </div>
    </div>
  );
}
