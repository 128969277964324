import React, { useState } from "react";
import "./CSS/HomePageServiceCard.css"; // Import the CSS file for styling
import { Home_DIV3_SERVICE } from "../Constants/Constants";
import { Link } from "react-router-dom";
export default function ServiceCard() {
  const getVisited = (index)=> {
    return 70*index
  }
  return (
    <div className="service-card">
      {Home_DIV3_SERVICE.map((item, index) => (
        <div className="card-details">
        
          <div className="card-data">
            <div className="detail-scroll">
              <h3 className="start">{item.number}</h3>
              <div className="line">
                <div className="visit" style={{  height: getVisited(++index) + 'px'}}></div>
              </div>
              <h3 className="last">05</h3>
            </div>
            <div className="details">
                <div className="title-and-desc">
                  <h3 className="title">{item.title}</h3>
                  <p className="description">{item.description}</p>
                  <Link to={'/programs'}><button className="check-now">   Check now </button></Link>
                </div>
                <div className="image-div">
                    <img src={item.imageUrl} alt={item.title} />
                </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
